<template>
  <div class="transportInvoiceDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.transportInvoice.id ? '编辑' : '新增' }}运输发票</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="data.transportInvoice.id == null">保存</el-button>
          <el-button type="primary" @click="setById()" v-if="data.transportInvoice.id != null">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="发票日期">
        <el-date-picker style="width: 100%;" v-model="data.transportInvoice.invoiceDate" type="date" />
      </el-form-item>
      <el-form-item label="运输公司">
        <el-select :disabled="data.transportInvoice.id" v-model="data.transportInvoice.transportCompany" filterable>
          <el-option v-for='transportCompanyItem in show.transportCompanyList' :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="开票数量">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.transportInvoice.productQuantity" />
      </el-form-item>
      <el-form-item label="开票金额">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.transportInvoice.amount" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  transportCompanyList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "transportInvoiceDetail")
const data = reactive({
  transportInvoice: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
})

api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.transportInvoice))
  param.invoiceDate = util.parseTime(param.invoiceDate, '{y}-{m}-{d}')
  api.post('/backend/transportInvoice/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = () => {
  const param = { id: data.transportInvoice.id }
  param.invoiceDate = util.parseTime(data.transportInvoice.invoiceDate, '{y}-{m}-{d}')
  param.productQuantity = data.transportInvoice.productQuantity
  param.amount = data.transportInvoice.amount
  api.post('/backend/transportInvoice/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

</script>

<style lang="less">
.transportInvoiceDetail {
  margin: auto;
  max-width: 900px;
}
</style>